import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Tasks, Tags, TeamsTeams, Users } from '../../constants/constants';

export const fetchTasks = createAsyncThunk(
  'tasks/fetchTasks',
  async function (_, { rejectWithValue }) {
    try {
      const response = await fetch(
        'https://back.devis.extim.su/boards/0/tickets'
      );
      if (!response.ok) {
        throw new Error('ServerError');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.status = 'rejected';
  state.error = action.payload;
};

const tasksSlice = createSlice({
  name: 'task',
  initialState: {
    tasks: Tasks,
    teams: TeamsTeams,
    users: Users,
    tags: Tags,
    status: null,
    error: null,
  },
  reducers: {
    // Пример редьюсеров, если потребуется их активировать
    // getTags(state, _) {
    //   return state;
    // },
    // addTags(state, action) {
    //   state.tasks[0].tags.push(action.payload);
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.tasks = action.payload;
      })
      .addCase(fetchTasks.rejected, setError);
  },
});

// Из комментариев удалены неиспользуемые экшены
export default tasksSlice.reducer;