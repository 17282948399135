import { Fragment } from "react";


const Th = ({
    children,
    onClick,
    numOfRows = '',
    numOfCols = '',
}) => {
    return (
        <Fragment>
            <th className="table-columns" onClick={onClick} rowSpan={numOfRows} colSpan={numOfCols}>
                {children}
            </th>
        </Fragment>
    );
};

export default Th;