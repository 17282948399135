import { React, Fragment, ReactNode } from 'react';
import './td.scss';



const Td = ({
    children,
    onClick,
    numOfRows = '',
    numOfCols = '',
}) => {
    return (
        <Fragment>
            <td className="cell-data" onClick={onClick} style={{}} rowSpan={numOfRows} colSpan={numOfCols}>
                {children}
            </td>
        </Fragment>
    );
};

export default Td;