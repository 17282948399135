import {
  ResultTableLabels,
  ResultTableLabelsRows,
} from "../../constants/constants";
import { useState, useEffect } from "react";
import Td from "./td/td";
import Th from "./th/th";
import "./table.scss";

const ResultTable = ({ results, inputValues, rowCount }) => {
  const numOfRow = Array.from(Object.keys(ResultTableLabelsRows));

  const res = results.map((el, i) => {
    const index = results[i]?.data.item_index;
    const { excel_file, intervals, input_data, ...restInputValues } =
      inputValues?.[index] || {};

    return {
      data: results[i]?.data,
      inputs: { ...restInputValues },
    };
  });

  const rem_inp = res?.map((el) => el?.inputs);
  //console.log(res);
  return (
    <div className="table-container">
      <table>
        <thead className="table-header">
          {numOfRow.map((el) => (
            <tr key={el}>
              {Object.keys(ResultTableLabelsRows[el]).map((label) => {
                return (
                  <Th
                    key={label}
                    numOfRows={ResultTableLabelsRows[el][label]?.rowspan}
                    numOfCols={ResultTableLabelsRows[el][label]?.colspan}
                    children={ResultTableLabels[label]}
                    size={"small"}
                  />
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {results.map((row, i) => (
            <tr className="row-data" key={i}>
              {Object.values(rem_inp[i] || {}).map((el, j) => (
                <Td className="cell-data" key={j}>
                  {el}
                </Td>
              ))}

              {res?.[i]?.["data"]?.["countresults"]?.[
                "Качество сцепления цемента с колонной"
              ] &&
                Object.values(
                  res?.[i]?.["data"]?.["countresults"]?.[
                    "Качество сцепления цемента с колонной"
                  ]
                ).map((cell, cellIndex) => (
                  <Td key={cellIndex} onClick={() => console.log("click")}>
                    {Math.round(cell)}
                  </Td>
                ))}
              {res?.[i]?.["data"]?.["countresults"]?.["Характер заполнения"] &&
                Object.values(
                  res?.[i]?.["data"]?.["countresults"]?.["Характер заполнения"]
                ).map((cell, cellIndex) => (
                  <Td key={cellIndex} onClick={() => console.log("click")}>
                    {Math.round(cell)}
                  </Td>
                ))}
              {res?.[i]?.["data"]?.["coeffresults"]?.["ККК"] !== undefined && (
                <Td>
                  {res?.[i]?.["data"]?.["coeffresults"]?.["ККК"]?.toFixed(3)}
                </Td>
              )}
              {res?.[i]?.["data"]?.["coeffresults"]?.["ККЗ"] !== undefined && (
                <Td>
                  {res?.[i]?.["data"]?.["coeffresults"]?.["ККЗ"]?.toFixed(3)}
                </Td>
              )}
              {res?.[i]?.["data"]?.["coeffresults"]?.["ККЦ"] !== undefined && (
                <Td>
                  {res?.[i]?.["data"]?.["coeffresults"]?.["ККЦ"]?.toFixed(3)}
                </Td>
              )}
              {res?.[i]?.["data"]?.["countresults"]?.["Интервал"] &&
                Object.values(
                  res?.[i]?.["data"]?.["countresults"]?.["Интервал"]
                ).map((cell, cellIndex) => (
                  <Td key={cellIndex} onClick={() => console.log("click")}>
                    {Math.round(cell)}
                  </Td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultTable;
