import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  calculationResults: [],
  uploadedFiles: [],
  inputValues: [],
};

const toolsSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {
    addCalculationResult(state, action) {
      state.calculationResults = [...state.calculationResults, action.payload];
    },
    addUploadedFile(state, action) {
      state.uploadedFiles = [...state.uploadedFiles, action.payload];
    },
    addInputValues(state, action) {
      state.inputValues = [...state.inputValues, action.payload];
    },
    deleteRow(state, action) {
      const index = action.payload;
      state.inputValues = state.inputValues.filter((_, i) => i !== index);
      state.uploadedFiles = state.uploadedFiles.filter((_, i) => i !== index);
    },
    clearCalculationResults(state) {
      state.calculationResults = [];
    },
    clearState(state) {
      state.inputValues = [];
      state.uploadedFiles = [];
      state.calculationResults = [];
    },
  },
});

export const {
  addCalculationResult,
  addUploadedFile,
  addInputValues,
  deleteRow,
  clearState,
  clearCalculationResults,
} = toolsSlice.actions;
export default toolsSlice.reducer;
