import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './headerBlock.scss';

const HeaderBlock = ({
  title,
  children,
  linksItem,
  addNewRowTitle,
  addRow,
}) => {

  return (
    <div className="header-block">
      <span className="header-title">{title}</span>
      <span className="header-component">{children}</span>
      <span className="header-links">
        {(linksItem && !addNewRowTitle) &&
          linksItem.map(link => (
            <Link
              key={link.title}
              className={`header-link header-link-${link.style}`}
              to={link.linkTo}
            >
              {link.title}
            </Link>
          ))}
          {addNewRowTitle &&
            <button className='add-row-button' onClick={addRow}>{addNewRowTitle}</button>
          }
      </span>
    </div>
  );
};

export default HeaderBlock;
