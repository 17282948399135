import React from 'react';
import './progressBar.scss';



const ProgressBar = ({ percent }) => {
  const getColor = (percent) => {
    if (percent >= 75) {
      return 'red';
    } else if (percent >= 50) {
      return 'yellow';
    } else if (percent >= 25) {
      return 'blue';
    }
    return 'green';
  };

  return (
    <div className="card-progress">
      <span className="progressbar-percent">{percent}%</span>
      <progress
        className={`progressbar progress-color-${getColor(percent)}`}
        value={percent}
        max="100"
      ></progress>
    </div>
  );
};

export default ProgressBar;