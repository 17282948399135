import React from "react";
import "./utilsInputPage.scss";
import HeaderBlock from "../../ui-kit/headerBlock";
import { Constants } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import InputList from "../../components/inputList";
import { useSelector, useDispatch } from "react-redux";

const UtilsInputPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rowCountFromState = useSelector(
    (state) => state.tools.uploadedFiles.length
  );
  const [rowCount, setRowCount] = React.useState(rowCountFromState || 1);

  const addRow = () => {
    setRowCount(rowCount + 1);
  };

  return (
    <div className="main-container utils-input-container">
      <HeaderBlock
        title={Constants.Tools.UtilsInput.utilsInputTitle}
        children={[]}
        addNewRowTitle={Constants.Tools.UtilsInput.utilsRowAdd}
        addRow={addRow}
      />
      <InputList rowCount={rowCount} setRowCount={setRowCount} />
    </div>
  );
};

export default UtilsInputPage;
