/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

// весить На OnChange
//upload
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
export const filesUpload = async (excelFile, organization_id) => {
  const url = `tools/files/upload/?organization_id=${organization_id}`;
  const res = await axios.post(url, excelFile, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
};

export const filesDelete = async (file_id) => {
  const url = `/tools/files/${file_id}/delete/`;
  const res = await axios.delete(url);
};

export const filesGet = async (organization_id) => {
  const url = `/tools/files/?organization_id=${organization_id}`;
  const { data } = await axios.get(url);
  return data;
};

export const createRuner = async (name) => {
  const url = `/tools/runner/add/`;
  const res = await axios.post(url, name);
  return res.data;
};

//after upload (count button)
//и возвращает
export const runTool = async (
  data,
  organization_id,
  tool_id,
  interval,
  index
) => {
  const url = `/tools/runner/${tool_id}/run/?organization_id=${organization_id}`;
  const payload = {
    data: data,
    interval,
    index,
  };

  const res = await axios.post(url, payload);
  return res.data;
};
export const create_excel = async (data) => {
  const url = `/tools/runner/create/`;
  const payload = {
    data: data,
  };

  const res = await axios.post(url, payload, {
    responseType: "blob",
  });
  const downloadUrl = window.URL.createObjectURL(
    new Blob([res.data], { type: res.headers["content-type"] })
  );
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", "sample_data.xlsx"); // Задаем имя файла для скачивания
  document.body.appendChild(link);
  link.click();
  link.remove();
};
