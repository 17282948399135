import React, { useState, useEffect } from "react";
import { UtilsInputListLabels, events } from "../../constants/constants";
import "./inputList.scss";
import trash from "../../assets/icons/files/trash.svg";
import { useNavigate } from "react-router-dom";
import UploadDropdown from "../../components/uploadDropdown";
import IntervalsModal from "../intervalsModal";
import {
  create_excel,
  filesUpload,
  runTool,
} from "../../services/tools.service";
import { ROUTES } from "../../utils/routes/routesConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  addCalculationResult,
  addInputValues,
  deleteRow,
  clearState,
  clearCalculationResults,
  addUploadedFile,
  setCurrentRowsCount,
} from "../../store/toolsRedux/toolsSlice";

const InputList = ({ rowCount, setRowCount }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputKeys = Object.keys(UtilsInputListLabels);
  const inputValues = useSelector((state) => state.tools.inputValues);
  const [tempInputValues, setTempInputValues] = useState(inputValues);
  const arrFromRows = Array.from({ length: rowCount }).map((el) => 1);
  const [isCountButtonDisabled, setIsCountButtonDisabled] = useState(false);
  const [isResultButtonDisabled, setIsResultButtonDisabled] = useState(true);
  const results = useSelector((state) => state.tools.calculationResults);
  const [isIntervalModalVisible, setIsIntervalModalVisible] = useState(false);
  let result;
  let response;

  useEffect(() => {
    setTempInputValues(inputValues);
  }, [inputValues]);

  useEffect(() => {
    let isCountButtonDisabled = true;

    tempInputValues?.forEach((el) => {
      if (
        el?.borehole_number &&
        el?.column_diameter &&
        el?.date &&
        el?.object_of_operation &&
        el?.profile &&
        el?.excel_file &&
        rowCount == inputValues?.length
      ) {
        isCountButtonDisabled = false;
      }
    });
    setIsCountButtonDisabled(isCountButtonDisabled);
  }, [tempInputValues]);

  useEffect(() => {
    if (results?.length) {
      setIsResultButtonDisabled(false);
    }
  }, [results]);

  const handleInputChange = (rowIndex, inputKey, value) => {
    const updatedTempValues = [...tempInputValues];
    updatedTempValues[rowIndex] = {
      ...updatedTempValues[rowIndex],
      [inputKey]: value,
    };
    setTempInputValues(updatedTempValues);
  };

  // useEffect(() => {
  //   dispatch(clearState());
  // }, [])
  const handleClearState = () => {
    dispatch(clearState());
  };
  const handleDeleteRowClick = (index, rowCount) => {
    if (rowCount > 1) {
      dispatch(deleteRow(index));

      const updatedTempValues = tempInputValues.filter((_, i) => i !== index);
      setTempInputValues(updatedTempValues);

      setRowCount(rowCount - 1);
    } else {
      // очистка всех инпутов в первой строке
      setTempInputValues([
        {
          borehole_number: "",
          column_diameter: "",
          date: "",
          object_of_operation: "",
          profile: "",
          excel_file: "",
        },
      ]);
      handleClearState();
    }
  };

  const createExcel = async (data) => {
    const data_create = data.map((el, i) => {
      const index = data[i].data.total.item_index;
      const { input_data, ...restInputValues } = inputValues?.[index] || {};

      return {
        data: data[i]?.data,
        inputs: { ...restInputValues },
      };
    });

    try {
      await create_excel(data_create);
      navigate(`/${ROUTES.UTILS_RESULT_PAGE}`);
    } catch (error) {
      console.error("Ошибка при создание excel файла:", error);
    }
  };

  const onCalculate = async () => {
    //kostyl
    dispatch(clearCalculationResults());
    const organization_id = "1";
    const tool_id = "1";

    const wellIds = tempInputValues.map((el) => el.excel_file);

    try {
      const promises = wellIds.map(async (id, index) => {
        const data = tempInputValues[index]?.input_data?.[id];
        const intervals = tempInputValues[index]?.intervals;

        if (intervals) {
          // Создаем массив промисов для каждого интервала
          return Promise.all(
            intervals.map((interval) =>
              runTool(data, organization_id, tool_id, interval, index)
            )
          );
        } else {
          // Возвращаем промис, если интервалов нет
          return runTool(data, organization_id, tool_id, null, index);
        }
      });

      const responses = await Promise.all(promises);

      // Плоский массив результатов (если были вложенные массивы)
      const flatResponses = responses.flat();

      // Dispatch all results at once
      flatResponses.forEach((result) => {
        dispatch(addCalculationResult(result));
      });

      createExcel(flatResponses);
    } catch (error) {
      console.error("Ошибка при расчете:", error);
    }
  };

  const handleUpload = (updatedInputValues) => {
    setTempInputValues(updatedInputValues);
  };

  const onResultsPage = () => {
    navigate(`/${ROUTES.UTILS_RESULT_PAGE}`);
  };

  const checkInputIsDisaled = (rowIndex) => {
    const stateQ = inputValues.length;
    if (rowIndex + 1 > stateQ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSetIntervals = (rowIndex, intervals) => {
    const updatedTempValues = [...tempInputValues];

    updatedTempValues[rowIndex] = {
      ...updatedTempValues[rowIndex],
      intervals,
    };

    setTempInputValues(updatedTempValues);
  };

  return (
    <div className="input-table-content">
      <table className="input-list-table">
        <thead>
          <tr className="input-table_header">
            {inputKeys?.map((key) => {
              if (key === "excel_file") {
                return null;
              }
              return (
                <th className="header-column" key={key}>
                  {UtilsInputListLabels[key]}
                </th>
              );
            })}
            <th className="header-column">Интервалы</th>
            <th className="header-column custom-dropdown-class">
              Метаданные (.xls, .xlsx)
            </th>

            <th className="remove-column"></th>
          </tr>
        </thead>
        <tbody>
          {arrFromRows.map((_, rowIndex) => (
            <tr className="table-rows" key={rowIndex}>
              {inputKeys?.map((key, i) => {
                if (key === "excel_file") {
                  return null;
                }
                return (
                  <td key={i}>
                    <input
                      className="input-details"
                      type={key === "date" ? "date" : "text"}
                      data-date-format={key === "date" && "DD MMMM YYYY"}
                      id={key}
                      name={key}
                      disabled={checkInputIsDisaled(rowIndex)}
                      placeholder={UtilsInputListLabels[key]}
                      value={tempInputValues?.[rowIndex]?.[key]}
                      onChange={(e) =>
                        handleInputChange(rowIndex, key, e.target.value)
                      }
                    />
                  </td>
                );
              })}
              <td>
                <button
                  className="input-details input-details-interval"
                  onClick={() => setIsIntervalModalVisible(true)}
                  disabled={checkInputIsDisaled(rowIndex)}
                >
                  Открыть
                </button>
                {isIntervalModalVisible && (
                  <IntervalsModal
                    inputValues={[tempInputValues]}
                    rowIndex={rowIndex}
                    onSetIntervals={handleSetIntervals}
                    onChangeModalVisible={setIsIntervalModalVisible}
                  />
                )}
              </td>
              <td className="input-details-dropdown">
                <UploadDropdown
                  inputValues={[tempInputValues]}
                  rowIndex={rowIndex}
                  onUpload={handleUpload}
                  disabled={checkInputIsDisaled(rowIndex)}
                />
              </td>

              <td>
                <div className="trash-container">
                  <img
                    alt="trash-icon"
                    src={trash}
                    onClick={() => handleDeleteRowClick(rowIndex, rowCount)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="count-btn-container">
        <button
          className="count-button"
          onClick={onCalculate}
          disabled={isCountButtonDisabled}
        >
          Рассчитать
        </button>
        <button
          className="results-button"
          onClick={onResultsPage}
          disabled={isResultButtonDisabled}
        >
          Результаты
        </button>
      </div>
    </div>
  );
};

export default InputList;
