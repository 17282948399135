import avatar from "../assets/image/png/polina.jpg";

export const Constants = {
  appLogo: "UR.team",
  projectTitle: "Мои проекты",
  projectCreate: "+ Создать",
  projectCreateLink: "#",
  taskTitle: "Мои задачи",
  taskCreate: "+ Создать",
  taskCreateLink: "#",
  recentTitle: "Недавние",
  cardTitle: "Команды:",
  create: "Создать",
  tasksListTitle: "Задачи",
  tasksListNewTask: "Все задачи",
  tasksListNewTaskLink: "#",
  tasksListMyTask: "Мои задачи",
  tasksListMyTaskLink: "#",
  newColumn: "Новый столбец",
  settingstTitle: "Настройки",
  Teams: {
    teamsTitle: "Команды",
    teamsAdd: "+ Добавить",
    teamsAddLink: "#",
    dropdownPlaceholder: "Выбор команды", //there was not such placeholder in design
    value: "",
  },
  Tools: {
    toolsTitle: "Сервисы",
    UtilsInput: {
      utilsInputTitle: "Загрузка данных о скважинах",
      utilsRowAdd: "+ Добавить",
      utilsUpload: "Загрузить",
      utilsAddFile: "Добавить",
    },
    UtilsResults: {
      title: "Коэффициент качества цементирования",
    },
  },
};

export const Tools = [
  {
    id: 1,
    name: "Анализ качества цементирования",
    color: "#84A59D",
    type: "analyse",
  },
  {
    id: 2,
    name: "Анализ качества цементирования",
    color: "#F5CAC3",
    type: "analyse",
  },
  {
    id: 3,
    name: "Анализ качества цементирования",
    color: "#8589BF",
    type: "analyse",
  },
  {
    id: 4,
    name: "Анализ качества цементирования",
    color: "#F6BD60",
    type: "analyse",
  },
  {
    id: 5,
    name: "Анализ качества цементирования",
    color: "#F28482",
    type: "analyse",
  },
  {
    id: 6,
    name: "Анализ качества цементирования",
    color: "#FBF8E7",
    type: "analyse",
  },
];

export const History = [
  {
    id: 1,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 2,
    title: "Команды",
    description: "Список команд",
    link: "#",
  },
  {
    id: 3,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 4,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 5,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 6,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 7,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 8,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 9,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 10,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 11,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 12,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 13,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 14,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
  {
    id: 15,
    title: "Проекты",
    description: "Список проектов",
    link: "#",
  },
];

export const Teams = [
  {
    name: "team1",
    color: "DarkOliveGreen",
    members: [
      "Olga Ivanova",
      "Ivan Surikov",
      "Ilia Rezvin",
      "Alina Solnceva",
      "Igor Zverev",
      "Viktor Oldin",
    ],
  },
  {
    name: "team2",
    color: "Indigo",
    members: [
      "Olga Ivanova",
      "Viktor Oldin",
      "Sergey Machanski",
      "Sergey Ivanov",
      "Oleg Polkin",
      "Sara Vasnesova",
    ],
  },
  {
    name: "team3",
    color: "DarkSlateGrey",
    members: ["Ilia Rezvin", "Alina Solnceva", "Igor Zverev", "Viktor Oldin"],
  },
  {
    name: "team4",
    color: "DarkCyan",
    members: [
      "Olga Ivanova",
      "Ivan Surikov",
      "Ilia Rezvin",
      "Alina Solnceva",
      "Igor Zverev",
      "Viktor Oldin",
    ],
  },
  {
    name: "team5",
    color: "DarkSlateBlue",
    members: [
      "Olga Ivanova",
      "Ilia Rezvin",
      "Sergey Ivanov",
      "Oleg Polkin",
      "Sara Vasnesova",
      "Alina Solnceva",
      "Igor Zverev",
      "Viktor Oldin",
      "Sergey Machanski",
      "Veles Odinovich",
    ],
  },
  {
    name: "team6",
    color: "DarkGoldenRod",
    members: ["Olga Ivanova", "Ivan Surikov"],
  },
];

export const Projects = [
  {
    id: 1,
    title: "Разработка платформы для оптовых продаж",
    teams: ["team2", "team3", "team6"],
    progress: 43,
  },
  {
    id: 2,
    title: "Создание трекера задач",
    teams: ["team1", "team2"],
    progress: 11,
  },
  {
    id: 3,
    title: "Создание документации для основного проекта",
    teams: ["team1"],
    progress: 43,
  },
  {
    id: 4,
    title: "Генерация идей",
    teams: ["team2", "team4"],
    progress: 78,
  },
  {
    id: 5,
    title: "Рефакторинг основного кода",
    teams: ["team6"],
    progress: 64,
  },
  {
    id: 6,
    title: "Рефакторинг основного кода",
    teams: ["team4", "team5"],
    progress: 100,
  },
  {
    id: 7,
    title: "Рефакторинг основного кода",
    teams: ["team2", "team3", "team4", "team5", "team6"],
    progress: 100,
  },
  {
    id: 8,
    title: "Рефакторинг основного кода",
    teams: ["team4", "team5"],
    progress: 100,
  },
  {
    id: 9,
    title: "Рефакторинг основного кода",
    teams: ["team2", "team5"],
    progress: 55,
  },
];

export const initialData = [
  {
    id: 1,
    title: "Новое",
    items: [
      {
        id: "1",
        title: "Найти дизайнера",
        subtitle: "Разработка платформы для оптовых продаж",
        status: "Новое",
        date: "28.07.2022",
        priority: 1,
        complited: false,
        teams: [
          {
            id: "team-2",
            name: "backend",
            color: "#FF02C7",
          },
        ],
      },
      {
        id: "2",
        title: "Подготовить отчет за июнь 2022",
        subtitle: "Создание трекера задач",
        status: "Новое",
        date: "10.07.2022",
        priority: 2,
        complited: false,
        teams: [
          {
            id: "team-1",
            name: "frontend",
            color: "blue",
          },
        ],
      },
      {
        id: "3",
        title: "План проекта",
        subtitle: "Новый проект",
        status: "Новое",
        date: "31.08.2022",
        priority: 0,
        complited: false,
        teams: [
          {
            id: "team-2",
            name: "backend",
            color: "#FF02C7",
          },
        ],
      },
      {
        id: "4",
        title: "Провести рабочую встречу",
        subtitle: "Новый проект",
        status: "Новое",
        date: "19.07.2022",
        priority: 3,
        complited: false,
        teams: [
          {
            id: "team-1",
            name: "frontend",
            color: "blue",
          },
          {
            id: "team-2",
            name: "backend",
            color: "#FF02C7",
          },
        ],
      },
      {
        id: "5",
        title: "Провести еще одну рабочую встречу",
        subtitle: "Новый проект",
        status: "Новое",
        date: "21.07.2022",
        priority: 2,
        complited: false,
        teams: [
          {
            id: "team-2",
            name: "backend",
            color: "#FF02C7",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "В работе",
    items: [],
  },
  {
    id: 3,
    title: "Завершено",
    items: [],
  },
];

export const Tasks = [
  {
    id: 1,
    title: "Найти дизайнера",
    subtitle: "Разработка платформы для оптовых продаж",
    status: "Новое",
    date: "28.07.2022",

    priority: 2,
    complited: false,
    users: [
      {
        id: 0,
        name: "Игнатова Полина",
        avatar: avatar,
      },
    ],
    description:
      "Повседневная практика показывает, что дальнейшее развитие различных форм деятельности позволяет оценить значение позиций, занимаемых участниками в отношении поставленных задач. Значимость этих проблем настолько очевидна, что консультация с широким активом позволяет оценить значение новых предложений. Идейные соображения высшего порядка, а также постоянный количественный рост и сфера нашей активности позволяет оценить значение модели развития.",
    teams: [
      {
        id: "team-2",
        name: "backend",
        color: "#84A59D",
      },
    ],
    tags: [
      {
        id: 1,
        text: "design",
      },
      {
        id: 2,
        text: "backend",
      },
      {
        id: 3,
        text: "frontend",
      },
    ],
  },
  {
    id: 2,
    title: "Подготовить отчет за июнь 2022",
    subtitle: "Создание трекера задач",
    status: "В работе",
    date: "10.07.2022",
    priority: 3,
    complited: false,
    users: [
      {
        id: 2,
        name: "Полина Игнатова",
        avatar: avatar,
      },
    ],
    description:
      "Повседневная практика показывает, что дальнейшее развитие различных форм деятельности позволяет оценить значение позиций, занимаемых участниками в отношении поставленных задач. Значимость этих проблем настолько очевидна, что консультация с широким активом позволяет оценить значение новых предложений. Идейные соображения высшего порядка, а также постоянный количественный рост и сфера нашей активности позволяет оценить значение модели развития.",
    teams: [
      {
        id: "team-1",
        name: "frontend",
        color: "#F5CAC3",
      },
    ],
    tags: [
      {
        id: 1,
        text: "design",
      },
      {
        id: 2,
        text: "backend",
      },
    ],
  },
  {
    id: 3,
    title: "Провести рабочую встречу",
    subtitle: "Новый проект",
    status: "В работе",
    date: "19.07.2022",

    priority: 1,
    complited: false,
    users: [
      {
        id: 4,
        name: "Полина Игнатова",
        avatar: avatar,
      },
    ],
    description:
      "Повседневная практика показывает, что дальнейшее развитие различных форм деятельности позволяет оценить значение позиций, занимаемых участниками в отношении поставленных задач. Значимость этих проблем настолько очевидна, что консультация с широким активом позволяет оценить значение новых предложений. Идейные соображения высшего порядка, а также постоянный количественный рост и сфера нашей активности позволяет оценить значение модели развития.",
    teams: [
      {
        id: "team-2",
        name: "backend",
        color: "#84A59D",
      },
    ],
    tags: [
      {
        id: 1,
        text: "design",
      },
      {
        id: 2,
        text: "backend",
      },
    ],
  },
  {
    id: 4,
    title: "План проекта",
    subtitle: "Новый проект",
    status: "В работе",
    date: "31.08.2022",
    priority: 1,
    complited: false,
    users: [
      {
        id: 6,
        name: "Игнатова Полина",

        avatar: avatar,
      },
    ],
    description:
      "Повседневная практика показывает, что дальнейшее развитие различных форм деятельности позволяет оценить значение позиций, занимаемых участниками в отношении поставленных задач. Значимость этих проблем настолько очевидна, что консультация с широким активом позволяет оценить значение новых предложений. Идейные соображения высшего порядка, а также постоянный количественный рост и сфера нашей активности позволяет оценить значение модели развития.",
    teams: [
      {
        id: "team-1",
        name: "frontend",
        color: "#F5CAC3",
      },
      {
        id: "team-2",
        name: "backend",
        color: "#84A59D",
      },
    ],
    tags: [
      {
        id: 1,
        text: "design",
      },
      {
        id: 2,
        text: "backend",
      },
      {
        id: 3,
        text: "frontend",
      },
    ],
  },
  {
    id: 5,
    title: "Цели на 2023 год",
    subtitle: "Новое",
    status: "В работе",
    date: "31.12.2022",
    priority: 2,
    complited: false,
    users: [
      {
        id: 82,
        name: "Полина Игнатова",
        avatar: avatar,
      },
    ],
    description:
      "Повседневная практика показывает, что дальнейшее развитие различных форм деятельности позволяет оценить значение позиций, занимаемых участниками в отношении поставленных задач. Значимость этих проблем настолько очевидна, что консультация с широким активом позволяет оценить значение новых предложений. Идейные соображения высшего порядка, а также постоянный количественный рост и сфера нашей активности позволяет оценить значение модели развития.",
    teams: [
      {
        id: "team-2",
        name: "hr",
        color: "#F6BD60",
      },
    ],
    tags: [
      {
        id: 1,
        text: "design",
      },
      {
        id: 2,
        text: "backend",
      },
      {
        id: 3,
        text: "frontend",
      },
    ],
  },
];

export const titleTaskBtn = [
  {
    id: 1,
    title: "Название",
    value: "title",
  },
  {
    id: 2,
    title: "Статус",
    value: "status",
  },
  {
    id: 3,
    title: "Дата",
    value: "date",
  },
  {
    id: 3,
    title: "Приоритет",
    value: "priority",
  },
];

export const events = [
  {
    id: 123,
    event: "Мит с frontend командой",
    time: "09:00",
  },
  {
    id: 124,
    event: "Презентация концепта",
    time: "11:30",
  },
  {
    id: 125,
    event: "Колл с дизайнером",
    time: "14:30",
  },
  {
    id: 126,
    event: "Мит с backend командой",
    time: "17:00",
  },
  {
    id: 127,
    event: "Мит с frontend командой",
    time: "18:00",
  },
  {
    id: 128,
    event: "Колл с дизайнером",
    time: "18:30",
  },
];
export const MySkills = [
  {
    id: 1,
    value: "html",
    label: "HTML",
  },
  {
    id: 2,
    value: "css",
    label: "CSS",
  },
  {
    id: 3,
    value: "javascript",
    label: "JavaScript",
  },
  {
    id: 4,
    value: "react.js",
    label: "React.js",
  },
  {
    id: 5,
    value: "node.js",
    label: "Node.js",
  },
  {
    id: 6,
    value: "react native",
    label: "React Native",
  },
  {
    id: 7,
    value: "python",
    label: "Python",
  },
  {
    id: 8,
    value: "django",
    label: "Django",
  },
  {
    id: 9,
    value: "design",
    label: "Design",
  },
  {
    id: 10,
    value: "adobe illustrator",
    label: "Adobe Illustrator",
  },
  {
    id: 11,
    value: "adobe photoshop",
    label: "Adobe Photoshop",
  },
  {
    id: 12,
    value: "blender",
    label: "Blender",
  },
  {
    id: 13,
    value: "figma",
    label: "Figma",
  },
];

export const Skills = [
  {
    id: 1,
    title: "HTML",
  },
  {
    id: 2,
    title: "CSS",
  },
  {
    id: 3,
    title: "JavaScript",
  },
  {
    id: 4,
    title: "React.js",
  },
  {
    id: 5,
    title: "Node.js",
  },
  {
    id: 6,
    title: "React Native",
  },
  {
    id: 7,
    title: "Python",
  },
  {
    id: 8,
    title: "Django",
  },
  {
    id: 9,
    title: "Design",
  },
  {
    id: 10,
    title: "Adobe Illustrator",
  },
  {
    id: 11,
    title: "Adobe Photoshop",
  },
  {
    id: 12,
    title: "Blender",
  },
  {
    id: 13,
    title: "Figma",
  },
];

export const Days = [
  {
    id: 1,
    title: "Пн",
    isChecked: true,
  },
  {
    id: 2,
    title: "Вт",
    isChecked: true,
  },
  {
    id: 3,
    title: "Ср",
    isChecked: true,
  },
  {
    id: 4,
    title: "Чт",
    isChecked: true,
  },
  {
    id: 5,
    title: "Пт",
    isChecked: true,
  },
  {
    id: 6,
    title: "Сб",
    isChecked: false,
  },
  {
    id: 7,
    title: "Вс",
    isChecked: false,
  },
];

export const NotifyMe = [
  {
    id: 1,
    title: "Новые задачи мне",
    email: true,
    push: false,
  },
  {
    id: 2,
    title: "Изменения в моих проектах",
    email: true,
    push: true,
  },
  {
    id: 3,
    title: "Предоставление доступа",
    email: false,
    push: true,
  },
  {
    id: 4,
    title: "События календаря",
    email: true,
    push: true,
  },
  {
    id: 5,
    title: "Мои упоминания",
    email: true,
    push: false,
  },
];

export const notifications = [
  {
    id: 234,
    date: "Сегодня, 10.07.2022 ",
    text: "Изменение в проекте 'Разработка платформы для оптовых продаж'",
    task: "Пользователь IgnatovaPO назначил(а) Вам задачу",
    notice: "Найти дизайнера",
    deadline: "до 28.07.2022",
  },
];

export const notificationEvents = [
  {
    id: 235,
    date: "Вчера, 09.07.2022 ",
    text: "Изменение в проекте 'Создание трекера задач'",
    task: "Пользователь IgnatovaPO добавил(а) новые файлы",
    notice: "UserFlow",
    files: "Общие файлы",
  },
  {
    id: 236,
    date: "Завтра, 11.07.2022 ",
    text: "Изменение в проекте 'Разработка платформы для оптовых продаж '",
    task: "Пользователь IgnatovaPO помог(а) выполнить задачу отдела на 25%",
    notice: "UserFlow",
    files: "Общие файлы",
  },
];

export const TeamsTeams = [
  {
    id: "team-1",
    name: "frontend",
    color: "#F5CAC3",
    type: "teams",
  },
  {
    id: "team-2",
    name: "backend",
    color: "#84A59D",
    type: "teams",
  },
  {
    id: "team-3",
    name: "hr",
    color: "#84439D",
    type: "teams",
  },
];
export const Users = [
  {
    id: "1",
    name: "Polina Ignatova",
    avatar: avatar,
    type: "users",
  },
  {
    id: "2",
    name: "Ignatova Polina",
    avatar: avatar,
    type: "users",
  },
  {
    id: "3",
    name: "Daria Ikon",
    avatar: avatar,
    type: "users",
  },
  {
    id: "4",
    name: "Ekaterina Gerts",
    avatar: avatar,
    type: "users",
  },
  {
    id: "5",
    name: "Devis Dumler",
    avatar: avatar,
    type: "users",
  },
  {
    id: "6",
    name: "Yulia Yulia",
    avatar: avatar,
    type: "users",
  },
  {
    id: "7",
    name: "Igor Igor",
    avatar: avatar,
    type: "users",
  },
  {
    id: "8",
    name: "Ignatova Polina",
    avatar: avatar,
    type: "users",
  },
];

export const Speciality = [
  {
    id: 1,
    title: "Frontend Developer",
  },
  {
    id: 2,
    title: "Backend Developer",
  },
  {
    id: 3,
    title: "Team Lead",
  },
  {
    id: 4,
    title: "Designer",
  },
  {
    id: 5,
    title: "Scrum Master",
  },
  {
    id: 6,
    title: "Project Manager",
  },
  {
    id: 7,
    title: "Tech Lead",
  },
  {
    id: 8,
    title: "Software Architect",
  },
];

export const Tags = [
  {
    id: 1,
    color: "rgba(0, 255, 0, 0.3)",
    name: "design",
    type: "tags",
  },
  {
    id: 2,
    color: "rgba(117, 0, 13, 0.3)",
    name: "backend",
    type: "tags",
  },
  {
    id: 3,
    color: "rgba(0, 0, 255, 0.3)",
    name: "frontend",
    type: "tags",
  },
  {
    id: 4,
    color: "rgba(153, 0, 255, 0.3)",
    name: "analytics",
    type: "tags",
  },
  {
    id: 5,
    color: "rgba(250, 0, 0, 0.3)",
    name: "hr",
    type: "tags",
  },
  {
    id: 6,
    color: "gray",
    name: "testing",
    type: "tags",
  },
];

export const UtilsInputListLabels = {
  borehole_number: "№ скв.",
  profile: "Профиль",
  date: "Дата",
  column_diameter: "Диаметр колонны",
  object_of_operation: "Объект эксплуатации",
  excel_file: "",
};

export const ResultTableLabels = {
  well_num: "№ скв.",
  profile: "Профиль",
  date: "Дата",
  column_diameter: "Диаметр колонны",
  operation_object: "Объект эксплуатации",
  operational_shank: "Эксплуатационный хвостовик",
  cement_adhesion_quality: "Кач-во сцепления цемента с колонной",
  hard: "Жесткий",
  partial: "Частичный",
  absent: "Отсутствует",
  cement_filling_quality: "Кач-во заполнения цемента",
  homogeneous: "Однородное",
  unilateral: "Одностороннее",
  contact_quality_factor: "Коэфф. качества контакта",
  filling_quality_factor: "Коэфф. качества заполнения",
  cementing_quality: "Кач-во цементирования",
  price1: "у.е",
  price2: "у.е",
  price3: "у.е",
  interval: "Интервал",
  interval_bottom: "Нижний",
  interval_top: "Верхний",
};

export const ResultTableLabelsRows = {
  firstRow: {
    well_num: {
      rowspan: 3,
    },
    profile: {
      rowspan: 3,
    },
    date: {
      rowspan: 3,
    },
    column_diameter: {
      rowspan: 3,
    },
    operation_object: {
      rowspan: 3,
    },
    operational_shank: {
      colspan: 8,
    },
    interval: {
      rowspan: 2,
      colspan: 12,
    },
  },
  secondRow: {
    cement_adhesion_quality: {
      colspan: 3,
    },
    cement_filling_quality: {
      colspan: 2,
    },
    contact_quality_factor: "no",
    filling_quality_factor: "no",
    cementing_quality: "no",
  },
  thirdRow: {
    hard: "no",
    partial: "no",
    absent: "no",
    homogeneous: "no",
    unilateral: "no",
    price1: "no",
    price2: "no",
    price3: "no",
    interval_bottom: "no",
    interval_top: "no",
  },
};

export const commentsForTask = [
  {
    text: "Пример комментария",
    user: {
      first_name: "First name",
      last_name: "Last name",
      thumbnail_url:
        "https://i.pinimg.com/1200x/2e/2e/21/2e2e2125ee53807c2d77b34773f84b5c.jpg",
    },
  },
];
