export const ROUTES = {
  AUTH: '/auth',
  MAIN: '/', // private
  ORGANIZATIONS: '/organizations',
  LOGIN: 'sign-in',
  REGISTRATION: 'sign-up',
  FILLING_PROFILE: 'create-profile', // private
  CHOOSE_COMPANY: 'choose-company', // выбор компании //private
  CREATE_COMPANY: 'create-company', // создать компанию //private
  JOIN_COMPANY: 'join-company', // присоединиться у уомпании //private
  TEAMS: 'teams',
  UTILS_INPUT_PAGE: 'tools/utils-input-page', // таблица для ввода данных о скважине для расчета
  TOOLS: 'tools',
  UTILS_RESULT_PAGE: 'tools/utils-result-page', // таблица результатов расчета
};
