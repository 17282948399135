// import HeaderBlock from '../../ui-kit/headerBlock';
// import './utilsResultPage.scss';
// import { Constants } from '../../constants/constants';
// import ChartContainer from '../../components/chartContainer/chartContainer';
// import Table from '../../components/table';
// import { useSelector, useDispatch } from 'react-redux';
// import { useState } from 'react';

// const UtilsResultPage = () => {

//   const calculationResults = useSelector(
//     state => state.tools.calculationResults
//   );
//   console.log("calculationResults + ")
//   console.log(calculationResults[0]?.data.total)
//   const coeffMap = {};
//   calculationResults[0]?.data?.total?.forEach(el => {
//     const results = JSON.parse(el?.data?.result);

//     results.forEach(result => {
//       const index = Object.keys(result)[0];
//       coeffMap[index] = result[index];
//     });
//   });
//   const dataSets = Object.entries(coeffMap).map(([id, data]) => ({ id, data }));
//   const inputValues = useSelector(state => state.tools.inputValues);
//   const [rowsQ, setRowsQ] = useState(inputValues.length);

//   return (
//     <div className="main-container tools-container result-tools-container">
//       <div className="page-container">
//         <HeaderBlock title={Constants.Tools.UtilsResults.title} children={[]} />
//         <ChartContainer results={dataSets} />
//         <Table results={dataSets} inputValues={inputValues} rowCount={rowsQ} />
//       </div>
//     </div>
//   );
// };

// export default UtilsResultPage;

import HeaderBlock from "../../ui-kit/headerBlock";
import "./utilsResultPage.scss";
import { Constants } from "../../constants/constants";
import ChartContainer from "../../components/chartContainer/chartContainer";
import Table from "../../components/table";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";

const UtilsResultPage = () => {
  const calculationResults = useSelector(
    (state) => state.tools.calculationResults
  );

  const dataSets = calculationResults
    ?.map((el, index) => {
      const nestedObject = el?.data.total;

      const countresults = nestedObject?.ration_count;
      const coeffresults = nestedObject?.ration_result;
      const item_index = nestedObject?.item_index;

      if (coeffresults && typeof coeffresults === "object") {
        return {
          id: String(index),
          data: { coeffresults, countresults, item_index },
        };
      } else {
        console.warn(
          `ration_result is undefined or not an object for result #${index}`
        );
        return null;
      }
    })
    .filter((item) => item !== null);

  const inputValues = useSelector((state) => state.tools.inputValues);
  const [rowsQ, setRowsQ] = useState(inputValues.length);

  return (
    <div className="main-container tools-container result-tools-container">
      <div className="page-container">
        <HeaderBlock title={Constants.Tools.UtilsResults.title} children={[]} />
        <ChartContainer
          results={dataSets}
          inputValues={inputValues}
          rowCount={rowsQ}
        />
        <Table results={dataSets} inputValues={inputValues} rowCount={rowsQ} />
      </div>
    </div>
  );
};

export default UtilsResultPage;
