import { Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";
import { ROUTES } from "../utils/routes/routesConstants";
import UtilsInputPage from "../pages/utilsInputPage/utilsInputPage";
import UtilsResultPage from "../pages/utilsResultPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<UtilsInputPage />} />
        <Route path={ROUTES.UTILS_RESULT_PAGE} element={<UtilsResultPage />} />
      </Routes>
    </div>
  );
}

export default App;
