import React, { useEffect, useState, useRef } from "react";
import "./intervalsModal.scss";

import {
  addUploadedFile,
  addInputValues,
  addCalculationResult,
} from "../../store/toolsRedux/toolsSlice";
import { useDispatch } from "react-redux";
import trash from "../../assets/icons/files/trash.svg";

const IntervalsModal = ({
  inputValues,
  rowIndex,
  onSetIntervals,
  onChangeModalVisible,
}) => {
  const tempIntervals = inputValues[0]?.[rowIndex]?.intervals;
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [intervals, setIntervals] = useState(
    tempIntervals || [{ top: "", bottom: "" }]
  );

  useEffect(() => {
    if (intervals.length === 4) {
      setIsButtonVisible(false);
    } else {
      setIsButtonVisible(true);
    }
  }, [intervals.length]);

  const handleAddInterval = () => {
    setIntervals([...intervals, { top: "", bottom: "" }]);
  };

  const handleSetIntervals = () => {
    onSetIntervals(rowIndex, intervals);
    handleModalVisibility();
  };

  const handleInputChange = (index, key, value) => {
    const updatedIntervals = [...intervals];
    updatedIntervals[index] = {
      ...updatedIntervals[index],
      [key]: value ? parseFloat(value) : "",
    };

    setIntervals(updatedIntervals);
  };

  const handleModalVisibility = () => {
    onChangeModalVisible(false);
  };

  const handleDeleteInterval = (index) => {
    const updatedIntervals = [...intervals];
    if (updatedIntervals.length === 1) {
      updatedIntervals[index] = { top: "", bottom: "" };
    } else {
      updatedIntervals.splice(index, 1);
    }

    setIntervals(updatedIntervals);
  };

  return (
    <div className="intervals-modal" onClick={handleModalVisibility}>
      <div
        className="intervals-modal-body"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="intervals-modal-body__header">Интервалы</div>

        {intervals.map((item, index) => (
          <div
            className="intervals-modal-body__input-wrapper"
            key={`interval-${index}`}
          >
            <input
              className="input-interval"
              placeholder="Нижняя граница"
              value={item.bottom}
              onChange={(e) =>
                handleInputChange(index, "bottom", e.target.value)
              }
            />
            <input
              className="input-interval"
              placeholder="Верхняя граница"
              value={item.top}
              onChange={(e) => handleInputChange(index, "top", e.target.value)}
            />

            <div className="trash-container input-interval">
              <img
                alt="trash-icon"
                src={trash}
                onClick={() => handleDeleteInterval(index)}
              />
            </div>
          </div>
        ))}

        {isButtonVisible && (
          <button
            className="intervals-modal-body__button"
            onClick={handleAddInterval}
          >
            Добавить интервал
          </button>
        )}

        <button
          className="intervals-modal-body__button"
          onClick={handleSetIntervals}
        >
          Готово
        </button>
      </div>
    </div>
  );
};

export default IntervalsModal;
