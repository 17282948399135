import React, { useEffect, useState, useRef } from "react";
import "./uploadDropdown.scss";
import corner from "../../assets/icons/files/corner.svg";
import fileIcon from "../../assets/icons/files/file-icon.svg";
import { Constants } from "../../constants/constants";
import { filesUpload } from "../../services/tools.service";
import ProgressBar from "../../ui-kit/progressBar";
import { useSelector, useDispatch } from "react-redux";
import {
  addUploadedFile,
  addInputValues,
  addCalculationResult,
} from "../../store/toolsRedux/toolsSlice";
import { runTool } from "../../services/tools.service";
import { useNavigate } from "react-router-dom";

const CustomDropdown = ({ inputValues, rowIndex, onUpload, disabled }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uploadedFiles = useSelector((state) => state.tools.uploadedFiles);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isTooltip, setIsTooltip] = useState(false);
  const fileInputRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files?.[0];
    setSelectedFile(file);
  };
  const prepareDataForBackend = (wellOutputData) => {
    let preparedData = {};

    for (let wellId in wellOutputData) {
      if (wellOutputData.hasOwnProperty(wellId)) {
        preparedData[wellId] = wellOutputData[wellId].map((record) => ({
          кровля: record["кровля"],
          подошва: record["подошва"],
          "Контакт цементного камня с колонной":
            record["Контакт цементного камня с колонной"],
          "Контакт цементного камня с породой":
            record["Контакт цементного камня с породой"],
          "Характер заполнения": record["Характер заполнения"],
          "дата расчета": record["дата расчета"],
          диаметр: record["диаметр"],
          мощность: record["мощность"],
          пласт: record["пласт"],
        }));
      }
    }

    return preparedData;
  };
  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("files", selectedFile);
      setIsUploading(true);

      try {
        const response = await filesUpload(formData, 1);
        simulateFileUpload();

        const uploadedFile = {
          id: response?.id,
          name: selectedFile.name,
        };

        dispatch(addUploadedFile(uploadedFile));

        const updatedInputValues = inputValues[0].map((input, index) => {
          if (index === rowIndex) {
            return {
              ...input,
              excel_file: uploadedFile?.id,
              input_data: response?.input_data,
            };
          }
          return input;
        });
        dispatch(addInputValues(updatedInputValues[rowIndex]));

        onUpload(updatedInputValues);

        // if (!response || !response.well_output_data) {
        //   console.error('Структура ответа:', response.data);
        //   throw new Error('Ответ не содержит well_output_data');
        // }
        // const preparedData = prepareDataForBackend(response.well_output_data);
        // const datares = response
        // try {
        //   const organization_id = '1';
        //   const tool_id = '1';
        //   const result = await runTool(preparedData, organization_id, tool_id);
        //   console.log(response)
        //   dispatch(addCalculationResult(result));
        //   navigate(`/tools/utils-result-page`);
        // } catch (error) {
        //     console.error('Ошибка при отправке данных на сервер:', error);
        //   throw error;
        // }

        // const wellOutputData = response.well_output_data;
        // const wellId = Object.keys(wellOutputData)[0]
        // const uploadedFile = {
        //   id: wellId,
        //   name: selectedFile.name,
        // };
        // console.log("id and name " + uploadedFile.id + " "+ uploadedFile.name)
        // dispatch(addUploadedFile(uploadedFile));

        // const updatedInputValues = inputValues[0].map((input, index) => {
        //   if (index === rowIndex) {
        //     return {
        //       ...input,
        //       excel_file: uploadedFile?.id,
        //     };
        //   }
        //   return input;
        // });
        // dispatch(addInputValues(updatedInputValues[rowIndex]));
        // console.log(updatedInputValues)
        // onUpload(updatedInputValues);
      } catch (error) {
        console.error("Ошибка при загрузке файла:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleAddClick = () => {
    if (disabled) return;
    fileInputRef.current.click();
  };

  const simulateFileUpload = () => {
    let currentProgress = 0;

    const updateProgress = () => {
      if (currentProgress <= 100) {
        setProgress((prev) => prev + 1);
        currentProgress += 1;
        setTimeout(updateProgress, 10);
      }
    };

    updateProgress();
    setProgress(0);
  };

  useEffect(() => {}, [progress, disabled]);

  return (
    <div className="custom-dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}>
        <span>{Constants.Tools.UtilsInput.utilsAddFile}</span>
        <img
          className={`dropdown-icon ${isOpen ? "rotate" : ""}`}
          alt="arrow"
          src={corner}
        />
      </div>
      {isOpen && (
        <div
          className="dropdown-content"
          onMouseEnter={() => disabled && setIsTooltip(true)}
          onMouseLeave={() => setIsTooltip(false)}
        >
          <div className="upload-button" onClick={handleAddClick}>
            <div style={{ minWidth: "14px" }}>+</div>
            Добавить
          </div>
          <button className="upload-button" onClick={handleUpload}>
            Загрузить
          </button>
          {uploadedFiles.map((file, index) => (
            <div key={index} className="uploaded-file">
              <img alt="file-icon" src={fileIcon} />
              {file.name}
            </div>
          ))}
          {isUploading && <ProgressBar percent={progress} />}
        </div>
      )}
      <input
        type="file"
        id="file-upload"
        accept=".xlsx"
        multiple={false}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        ref={fileInputRef}
      />
      <div className={isTooltip ? "tooltip" : "disabled"}>
        Невозможно загрузить файл в строку с уже произведенными расчетами
      </div>
    </div>
  );
};

export default CustomDropdown;
