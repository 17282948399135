import React, { FC } from "react";
import "./chartContainer.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import Chart from "../../components/chart/chart";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const ChartContainer = ({ results, inputValues, rowCount }) => {
  const arrFromRows = Array.from({ length: rowCount }).map((el) => 1);

  const res = arrFromRows.map((el, i) => {
    const { excel_file, input_data, ...restInputValues } =
      inputValues?.[i] || {};
    return {
      data: results[i]?.data,
      inputs: { ...restInputValues },
    };
  });

  const rem_inp = res?.map((el) => el?.inputs);

  const boreholes = inputValues.map((el) => el.borehole_number);

  const KKKdata = (results || [])
    .map((item) => ({
      label: boreholes[item?.id],
      value: Number(item?.data?.["coeffresults"]?.["ККК"]) || 0,
    }))
    .sort((a, b) => b.value - a.value);
  const KKZdata = (results || [])
    .map((item) => ({
      label: boreholes[item?.id],
      value: Number(item?.data?.["coeffresults"]?.["ККЗ"]) || 0,
    }))
    .sort((a, b) => b.value - a.value);
  const KKCdata = (results || [])
    .map((item) => ({
      label: boreholes[item?.id],
      value: Number(item?.data?.["coeffresults"]?.["ККЦ"]) || 0,
    }))
    .sort((a, b) => b.value - a.value);

  const getBarColors = (value, min, max) => {
    const range = max - min;
    const third = range / 3;

    const colors = [];
    value.forEach((el) => {
      if (el > max - third) {
        colors.push("#4CAF50");
      } else if (el > max - 3 * third) {
        colors.push("#FFC300");
      } else {
        colors.push("#FF5733");
      }
    });

    return colors;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: { stacked: false, grid: { color: "rgba(0, 0, 0, 0)" } },
      y: {
        beginAtZero: false,
        max: 1,
        min: 0,
        grid: { color: "rgba(0, 0, 0, 0)" },
      },
    },
  };

  const data = (boreholes, data) => {
    const labels = data.map((item) => item.label);
    const values = data.map((item) => item.value);
    const min = Math.min(...values);
    const max = Math.max(...values);

    const colors = [getBarColors(values, min, max)];
    return {
      labels: labels,
      datasets: [
        {
          clip: 1,
          label: false,
          data: values,
          backgroundColor: colors[0],
          borderWidth: 0,
          borderRadius: 6,
          barThickness: 54,
        },
      ],
    };
  };

  return (
    <div className="chart-container">
      <div className="first-chart" style={{ height: "670px", width: "626px" }}>
        <div className="chart-title">Коэффициент качества контакта</div>
        <div className="first-chart-bar" style={{ height: "527x" }}>
          <Chart
            data={data(boreholes, KKKdata)}
            options={options}
            height={527}
          />
        </div>
      </div>
      <div className="second-chart" style={{ height: "327px", width: "574px" }}>
        <div className="chart-title">Коэффициент качества заполнения</div>
        <div className="second-chart-bar">
          <Chart
            data={data(boreholes, KKZdata)}
            options={options}
            height={200}
          />
        </div>
      </div>
      <div className="third-chart" style={{ height: "327px", width: "574px" }}>
        <div className="chart-title">Качество цементирования</div>
        <div className="third-chart-bar">
          <Chart
            data={data(boreholes, KKCdata)}
            options={options}
            height={200}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartContainer;

// import React from 'react';
// import './chartContainer.scss';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import Chart from '../../components/chart/chart';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

// const ChartContainer = ({ results }) => {
//   console.log('ChartContainer results:', results);

//   // Извлечение данных для графиков и сортировка
//   const extractData = (type) => {
//     return results.map(item => ({
//       id: item.id,
//       value: Number(item.data[type]) || 0
//     })).sort((a, b) => b.value - a.value);
//   };

//   const KKKdata = extractData('ККК');
//   const KKZdata = extractData('ККЗ');
//   const KKCdata = extractData('ККЦ');

//   // Функция для получения цветов столбцов
//   const getBarColors = (values, min, max) => {
//     const range = max - min;
//     const third = range / 3;

//     return values.map(value => {
//       if (value > max - third) {
//         return '#FF5733';
//       } else if (value > max - 2 * third) {
//         return '#FFC300';
//       } else {
//         return '#4CAF50';
//       }
//     });
//   };

//   // Опции для графиков
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: { display: false },
//     },
//     scales: {
//       x: { stacked: false, grid: { color: 'rgba(0, 0, 0, 0)' } },
//       y: {
//         beginAtZero: false,
//         max: 1,
//         min: 0,
//         grid: { color: 'rgba(0, 0, 0, 0)' },
//       },
//     },
//   };

//   // Создание данных для графиков
//   const createChartData = (data) => {
//     const ids = data.map(item => item.id);
//     const values = data.map(item => item.value);
//     const min = Math.min(...values);
//     const max = Math.max(...values);

//     const colors = getBarColors(values, min, max);

//     return {
//       labels: ids,
//       datasets: [
//         {
//           clip: 1,
//           label: false,
//           data: values,
//           backgroundColor: colors,
//           borderWidth: 0,
//           borderRadius: 6,
//           barThickness: 54,
//         },
//       ],
//     };
//   };

//   return (
//     <div className="chart-container">
//       <div className="first-chart" style={{ height: '670px', width: '626px' }}>
//         <div className="chart-title">Коэффициент качества контакта</div>
//         <div className="first-chart-bar" style={{ height: '527px' }}>
//           <Chart
//             data={createChartData(KKKdata)}
//             options={options}
//             height={527}
//           />
//         </div>
//       </div>
//       <div className="second-chart" style={{ height: '327px', width: '574px' }}>
//         <div className="chart-title">Коэффициент качества заполнения</div>
//         <div className="second-chart-bar">
//           <Chart data={createChartData(KKZdata)} options={options} height={200} />
//         </div>
//       </div>
//       <div className="third-chart" style={{ height: '327px', width: '574px' }}>
//         <div className="chart-title">Качество цементирования</div>
//         <div className="third-chart-bar">
//           <Chart data={createChartData(KKCdata)} options={options} height={200} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChartContainer;
